// banner css start
.hero-area {
  padding: 200px 0 260px;

  @include mobile-xs {
    padding: 190px 0 100px;
  }

  @include desktop-xl {
    padding: 150px 0 260px;
  }

  p {
    opacity: 0.70;
  }

  h1 {
    line-height: 68px;
    z-index: 1;

    @include mobile-xs {
      font-size: 50px;
    }
  }

  .bg-shape-1 {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
  }

  .bg-shape-2 {
    position: absolute;
    left: 0;
    top: 185px;
    z-index: -1;
  }

  .bg-shape-3 {
    position: absolute;
    left: -40px;
    bottom: -50px;
    z-index: -1;
  }

  .bg-shape-4 {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;

    @include desktop-xl {
      max-width: 770px;
      right: -100px;
    }

    @include desktop-lg {
      max-width: 700px;
    }

    @include mobile {
      max-width: 500px;
    }
  }
}

.hero-area-lg {
  overflow: hidden;

  .row {
    margin-right: -25%;

    @include desktop {
      margin-right: -15px;
    }
  }
}

.watermark {
  position: absolute;
  opacity: 0.07;
  top: 20px;
  left: 0;
  font-size: 200px;
  font-weight: 600;
  z-index: -1;
  color: inherit;

  @include mobile {
    top: 0;
    font-size: 120px;
  }
}

.watermark-sm {
  position: absolute;
  opacity: 0.07;
  top: -40px;
  left: 0;
  font-size: 110px;
  font-weight: 600;
  color: inherit;

  @include mobile {
    top: 0;
    font-size: 100px;
  }
}