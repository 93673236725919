// vaariables
$primary-color: #007ac7;
$text-dark:#242f3e;
$secondary-color:#00e0d8;
$text-color:#8b8e93;
$text-light:#d4dae3;
$white: #fff;
$border-color: #2d3041;
$bg-gradient-primary: linear-gradient(134deg, #00a4db 0%, #00269e 100%);
$bg-gradient-secondary:linear-gradient( 80deg, rgb(0,48,204) 0%, rgb(0,164,219) 100%);
$btn-gradient-primary: linear-gradient(45deg, rgb(0, 168, 244) 0%, rgb(2, 209, 161) 100%);
$bg-secondary: #15192b;
$gray: #8b8e93;
$black: #000;

$icon-font: 'FontAwesome';
$primary-font: 'Poppins',
sans-serif;
$secondary-font: 'Open Sans',
sans-serif;
  