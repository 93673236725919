.blog-slider {
  .arrow-left,
  .arrow-right {
    position: absolute;
    top: -80px;
    right: 0;
    cursor: pointer;
  }

  .arrow-right {
    right: 17px;
  }

  .arrow-left {
    right: 77px;
    z-index: 1;
  }
}

/* sidebar */
.widget{
  padding: 40px 30px 0;
}

.search-btn{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: 0;
}

.tag-list {
  li {
    a {
      padding: 12px 15px;
      display: block;
      border-radius: 40px;

      &:hover {
        background: $btn-gradient-primary;
        color: $white !important;
      }
    }
  }
}

/* sidebar */

/* blog single */

blockquote {
  box-shadow: inset 0px 7px 0px 0px rgba(255, 255, 255, 0.596);
  padding: 34px 40px 37px 40px;
  background-image: $bg-gradient-secondary;
  border-radius: 10px;
  margin: 40px 0;
  p {
    position:relative;
    font-style: italic;
    color: $white;
    /* &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -10px;
      width: 55px;
      height: 40px;
      background: url(images/blockquote.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.7;
    } */
  }
  h6{
    color: $white;
  }
}

.media {
  img {
    height: 75px;
    width: 75px;
  }

  .reply-btn {
    font-size: 16px;
    font-weight: 500;
  }
}

form {
  textarea {
    height: 180px;
  }
}

/* /blog single */